<template>
  <CareForm v-if="this.site === 'CARE'"></CareForm>
  <PhysioForm v-else-if="this.site === 'PHYSIO'"></PhysioForm>
</template>

<script>
import { SITE } from "@/config";
import PhysioForm from "@/components/physio-form.vue";
import CareForm from "@/components/care-form.vue";

export default {
  components: { PhysioForm, CareForm },
  data() {
    return {
      site: SITE
    };
  },
  computed: {
    // termsURL() {
    //   if (SITE === "CARE") {
    //     return "/assets/careCompare_terms.pdf";
    //   } else if (SITE === "PHYSIO") {
    //     return "/assets/physioCompare_terms.pdf";
    //   }
    //   return "";
    // },
    // privacyURL() {
    //   if (SITE === "CARE") {
    //     return "/assets/careCompare_privacy.pdf";
    //   } else if (SITE === "PHYSIO") {
    //     return "/assets/physioCompare_privacy.pdf";
    //   }
    //   return "";
    // }
  },
  methods: {}
};
</script>

<style scoped></style>
